@import '../../../../scss/theme-bootstrap';

.product-smart-gift-overlay {
  margin-bottom: 20px;
  &__header-container {
    .smart-gift-header {
      font-family: $bb-sans;
      font-size: 30px;
      text-transform: uppercase;
      padding: 15px;
      margin-bottom: 25px;
    }
  }
  &__section {
    margin-top: 20px;
    padding: 0 20px;
    &-header {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
    }
  }
  &__colorbox {
    &#colorbox {
      z-index: 9999999;
      background-color: $color-white;
    }
    #cboxLoadedContent {
      padding: 0;
    }
    #cboxClose {
      color: $color-black;
      margin: 20px;
      top: 24px;
    }
  }
}

#cboxOverlay {
  &.product-smart-gift-overlay {
    &__colorbox {
      background: $color-black;
    }
  }
}
